@import '../../../styles/variables.scss';

.trialHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: sticky;
  padding: 10px;
  top: 0;
  z-index: 20;
}
.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

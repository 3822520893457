@import '../../../styles/variables.scss';

.container {
  z-index: 10;
  border: none;
  position: sticky;
  top: 0;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0px 32px;
}

.bgGray {
  background-color: $gray-100;
}

.projectName {
  margin-left: 16px;
  color: $gray-500;
}

.leftActions {
  display: flex;
  align-items: center;
}

.rightActions {
  display: flex;
  align-items: center;
}

.breadcrumbContainer {
  display: flex;
  align-items: center;
}

.breadcrumb {
  display: inline-flex;
  color: $gray-500;
}

.userButtonContainer {
  display: flex;
  align-items: center;
}

.companyLogo {
  padding: 1px 0;
  margin-right: 5px;
}

.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  border: 1px solid $gray-300;
  background-color: $gray-800;
  color: $white;
}

.userDropdown {
  &:nth-child(1) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
    border: none;
  }

  &:nth-child(2) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
    border: none;
  }

  &:nth-child(3) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
    border: none;
  }

  &:nth-child(1) > button > div {
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 0 6px;
  }
  &:nth-child(2) > button > div {
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 0 6px;
  }

  &:nth-child(3) > button > div {
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 0 6px;
  }

  &:nth-child(1) > ul {
    width: auto;
    right: 15px;
  }

  &:nth-child(2) > ul {
    width: auto;
    right: 15px;
  }

  &:nth-child(3) > ul {
    width: auto;
    right: 15px;
  }
}

.userDropdownNoLogo {
  width: 50px;

  &:nth-child(1) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
  }

  &:nth-child(2) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
  }

  &:nth-child(3) > button {
    justify-content: flex-end;
    padding: 0 0 0 5px;
  }

  &:nth-child(1) > ul {
    width: auto;
    top: 50px;
    right: 20px;
  }

  &:nth-child(2) > ul {
    width: auto;
    top: 50px;
    right: 20px;
  }

  &:nth-child(3) > ul {
    width: auto;
    top: 50px;
    right: 20px;
  }
}

.refreshDates {
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: $gray-500;
  margin-right: 8px;
  flex: 1;
}

.infoIconButton {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  min-width: 20px;
}

.tooltipContainer {
  cursor: pointer;
  min-width: 260px;
}

.tooltipContent {
  padding: 8px;
}

.tooltipText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $gray-900;
  ul {
    margin-top: 5px;
    padding-inline-start: 12px;
  }
}
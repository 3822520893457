@import '../../components/button/button.module.scss';

.premiumModal {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .icon {
    width: 94px;
    height: 94px;
  }

  .premTxt {
    color: var(--violet);
  }

  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.content {
  padding: 0px 12px;
  min-height: 22vh !important;

  p {
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-500);
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: center;
}

@import '../../styles/variables.scss';

.popOver {
  width: 280px;
  height: 280px;
  padding: 10px;

  &:nth-child(1) .ant-popover-arrow > .ant-popover-arrow-content {
    :before {
      background: $gray-100;
    }
  }

  &:nth-child(1) .ant-popover-inner {
    border-radius: 16px;
    background-color: $gray-100;
  }

  &:nth-child(1) .ant-popover-inner {
    border-radius: 16px;
  }

  &:nth-child(1) .ant-popover-inner > .ant-popover-inner-content {
    padding: 15px;
  }

  &:nth-child(1)
    .ant-popover-inner
    > .ant-popover-inner-content
    > .ant-popover-message
    > .ant-popover-message-title {
    padding-left: 0;
  }

  &:nth-child(1)
    .ant-popover-inner
    > .ant-popover-inner-content
    > .ant-popover-buttons
    > button {
    &:nth-child(1) {
      display: none;
    }
  }

  &:nth-child(1)
    .ant-popover-inner
    > .ant-popover-inner-content
    > .ant-popover-buttons
    > button {
    &:nth-child(2) {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: none;
      background-color: $violet;
      font-weight: bold;
      padding: 5px 0;
      margin: 0;
    }
  }
}

.--H2-Semibold {
  /* H2 / Semibold */

  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 88px;
  /* identical to box height */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--H4-Semibold {
  /* H4 / Semibold */

  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--H5-Semibold {
  /* H5 / Semibold */

  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--H5-Regular {
  /* H5 / Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Subheading-Semibold {
  /* Subheading / Semibold */

  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Subheading-Medium {
  /* Subheading / Medium */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Subheading-Regular {
  /* Subheading / Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Body-Small-Medium {
  /* Body / Medium */

  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Body-Small-Regular {
  /* Body /Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Caption-Semibold {
  /* Caption / Semibold */

  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Caption-Medium {
  /* Caption / Medium */

  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: var(--gray-900);
}

.--Caption-Regular {
  /* Caption / Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Legend-Medium {
  /* Legend / Medium */

  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Legend-Regular {
  /* Legend / Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Micro-Medium {
  /* Micro / Medium */

  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */

  color: #263238;
}

.--Micro-Regular {
  /* Micro / Regular */

  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Greyscale / Grey 900 */
  color: #263238;
}

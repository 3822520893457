.tooltipContainer {
  width: 100%;
  background-color: var(--gray-100);
  border-radius: 10px;
  padding: 20px;
}

.tooltipFooter {
  display: flex;
  justify-content: flex-end;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

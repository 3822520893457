@import '../../styles/variables.scss';

.baseContainer {
  display: flex;
  position: relative;
  height: 4px;
  border-radius: 100px;
  flex: 1;
  order: 0;
  margin: 20px 0px;
  overflow: hidden;
}

.defaultContainer {
  background: $gray-100;
}

.progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: $tertiary;
  border-radius: 100px;
  transition: 1s ease;
  transition-delay: 0.5s;

  &.infinite {
    width: 25% !important;
    animation: runLoop 2s linear infinite;
  }
}

.progressStriped {
  background-image: linear-gradient(
    135deg,
    #ffffff 25%,
    #e36933 25%,
    #a80867 50%,
    #ffffff 50%,
    #ffffff 75%,
    #e36933 75%,
    #a80867 100%
  );
  background-size: 1rem 1rem;
  animation-name: progressKeyframes;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.stripedError {
  background-image: linear-gradient(
    135deg,
    #e3ebf0 25%,
    #c2d1d9 25%,
    #c2d1d9 50%,
    #e3ebf0 50%,
    #e3ebf0 75%,
    #c2d1d9 75%,
    #c2d1d9 100%
  );
  background-size: 1rem 1rem;
}

.complete {
  background: #20bf73;
}

.error {
  background: #c2d1d9;
}

@keyframes progressKeyframes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10rem 0;
  }
}

@keyframes runLoop {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(200%);
  }
  75% {
    transform: translateX(300%);
  }
  100% {
    transform: translateX(400%);
  }
}

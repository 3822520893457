@import '../../../styles/typography.scss';
@import '../../../components/button/button.module.scss';

.container {
  position: fixed;
  z-index: 10000000;
  right: 90px;
  bottom: 16px;
  border-radius: 16px;
  padding: var(--m);
  width: 288px;
  background-color: var(--white);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

.modalDiv {
  display: flex;
  justify-content: space-between;
}

.modalTitleDiv {
  display: flex;
  .jobsRunningDiv {
    width: 24px;
    height: 24px;
    display: flex;
    background: #f7f8fb;
    border-radius: 34px;
    .jobsRunning {
      @extend .--Micro-Medium;
      color: #de0788;
      margin: auto;
    }
  }

  .modalTitle {
    @extend .--Body-Small-Medium;
    color: var(--gray-900);
    margin-left: var(--s);
  }
}

.description {
  @extend .--Body-Small-Regular;
  color: var(--gray-500);
  display: unset;
}

.loadingScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  color: var(--theme-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  font-weight: bold;
  text-align: center;
}

.text {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 62px;
  color: #263238;
  font-weight: 200;
  // display: flex;
  // align-items: center;
  // text-align: center;
}

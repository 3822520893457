@import '../../styles/typography.scss';

.container {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 0px;
  border-radius: 8px;
  cursor: text;
}

.container:focus-within {
  border-color: var(--gray-500);
}

.container:hover {
  background-color: var(--gray-100);
  border-color: var(--gray-200);
}

.compactContainer {
  @extend .container;
  padding: 8px 0px;
}

.textBox {
  @extend .--Caption-Regular;
  margin: 0px;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  background: none;
  padding: 0px;
  caret-color: var(--violet);
}

.textBox::placeholder {
  color: var(--gray-400);
}

.textBox:active {
  border: none;
  border-width: 0px;
}

.textBox:focus {
  border: none;
  border-width: 0;
  outline: none;
}

.leftComponent {
  height: 24px;
  border: none;
  display: flex;
  margin-left: 12px;
  align-items: center;
  justify-content: center;
}

.rightComponent {
  height: 24px;
  border: none;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autocompleteBackground {
  position: relative;
}

.suggestions {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px 8px;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 4px 10px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
}

.selectAll {
  @extend .--Caption-Semibold;
  border-bottom: 1px solid var(--gray-200);
  color: var(--gray-500);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px !important;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 4px 10px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.suggestion {
  @extend .--Caption-Medium;
  padding: 8px;
  border-radius: 8px;
  color: var(--gray-500);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestionLabel {
  cursor: pointer;
  width: 100%;
  overflow-wrap: anywhere;
  hyphens: auto;
  width: 90%;
}

.categorySuggestionLabel {
  padding-left: 1rem;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  overflow-wrap: anywhere;
  hyphens: auto;
  width: 90%;
}

.categoryLabel {
  font-weight: 900;
  cursor: pointer;
  width: 100%;
  overflow-wrap: anywhere;
  hyphens: auto;
  width: 90%;
}

.suggestionParagraph {
  margin-bottom: 0px;
}

.unhighlightedSuggestion {
  margin: 0px;
}

.highlightedSuggestion {
  @extend .unhighlightedSuggestion;
  color: var(--gray-900);
}

.suggestion:hover {
  background-color: var(--gray-100);
}

.suggestion:active {
  background-color: var(--gray-200);
}

/* Hide arrows from input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.inputError {
  font-size: 0.75rem;
  color: var(--danger);
}

.autoCompleteContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 200px;
  z-index: 0;
}

.autoCompleteInputContainer {
  width: 100%;
  border: 1.72px #DDDDDD solid;
  min-height: 41.35px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 41.35px;
  letter-spacing: 0.34px;
  padding: 0 1px 0 1px;
}

.icon {
  position: absolute;
  left: 16px;
}

.autoCompleteInput {
  border: none;
  width: 100%;
  height: 41.35px;
  padding-left: 40px;
  outline: none;
  border-radius: 4px;
}

.autoCompleteUl {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 260px;
  background-color: #fff;
  padding: 0px 8px 10px 8px;
  text-align: left;
  border: 1px #dddddd solid;
  z-index: 1;
  top: 40.35px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.autoCompleteUlParent {
  margin-left: 12px;
}

.automCompleteLiParent {
  font-size: 10px;
  font-weight: 400;
  margin-left: 12px;
}

.autoCompleteLi {
  display: flex;
  align-items: center;  
  margin-left: 12px;
  font-size: 12px;
  &:hover {
    background-color: #DDDDDD;
  }
}
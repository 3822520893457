@import '../../../styles/typography.scss';
@import '../../../components/button/button.module.scss';

.container {
  background-color: var(--gray-100);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: var(--white);
  width: 400px;
  height: 580px;
  padding: 1rem 0;
  border-radius: 5px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-rows: 150px 230px 200px;
  grid-template-columns: 400px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.welcomeImage {
  img {
    width: 100%;
    height: 100%;
  }
}

.footer {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  button {
    margin: 1rem auto;
    width: 60%;
  }

  small {
    color: var(--gray-400);
  }
}

@import './fonts.scss';
@import './spacing.scss';
@import './colors.scss';
@import './overrides.scss';

/* Customize scrollbars globally */

/* Firefox */
body {
  scrollbar-color: #a0a0a0 white;
}

/* other browsers */
::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 16px;
  border: 0;
}

::-webkit-scrollbar {
  background-color: #a0a0a0;
  height: 10px;
  width: 10px;
}

:root {
  --theme-font-family: Graphik, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  font-size: 16px;
}

html {
  background-color: var(--color-gray-9);
}

body {
  margin: 0;
  font-family: var(--theme-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

html,
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

a {
  color: var(--theme-primary);
}

/** Shared Table Styles **/
table {
  width: 100%;
}

td,
th {
  padding: 1rem 2rem;
}

tr:not(:last-child) td {
  border-bottom: 1px solid var(--theme-border-light);
}

th {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--text-secondary);
  font-weight: normal;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

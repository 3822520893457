@import '../../styles/typography.scss';

.container {
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0px;
}

.basicContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0px;
}

.container:hover {
  background-color: var(--gray-200);
}

.container:active {
  background-color: var(--gray-300);
}

.hiddenCheckBox {
  margin-left: -16px;
  opacity: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.hiddenCheckBox:focus-visible + label {
  background-color: red;
}

.description {
  @extend .--Caption-Regular;
  margin-left: 12px;
}

.checkedBox {
  width: 16px;
  height: 16px;
  background: var(--secondary);
  border-radius: 4px;
  display: flex;
}

.uncheckedBox {
  @extend .checkedBox;
  background: none;
  border: 2px solid var(--gray-500);
}

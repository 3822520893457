@import '../../../styles/variables.scss';

.wrapper {
  background-color: $gray-100;
  height: 100%;
  width: 100%;
}

// patch for "@flatfile/react": "^7.2.5"
div[data-testid='close-confirm-modal'] > div > div > div > div {
  color: #263238;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('../assets/fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/Graphik-Light.woff2') format('woff2'),
    url('../assets/fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/Graphik-Medium.woff2') format('woff2'),
    url('../assets/fonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/Graphik-Semibold.woff2') format('woff2'),
    url('../assets//fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@import '../../../styles/typography.scss';
@import '../../../components/button/button.module.scss';

.container {
  background-color: var(--gray-100);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: var(--white);
  width: 400px;
  height: 580px;
  padding: 1rem 0;
  border-radius: 5px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-rows: 150px 270px 160px;
  grid-template-columns: 400px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  .logo {
    height: 4rem;
  }
}

.main {
  padding: 1rem;
  text-align: center;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--gray-700);
  }

  .message {
    padding: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-800);
  }
}

.footer {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  button {
    margin: 1rem auto;
    width: 60%;
  }
}

@keyframes AppLoading {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  color: var(--pink_3_0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  font-weight: bold;
  text-align: center;
}

.view > div {
  animation: AppLoading 1 1s;
}

.view small {
  font-size: 2rem;
}

.logo {
  height: 10rem;
}

.spinnerDiv {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: -webkit-fill-available;
}
.spinnerDiv-medium {
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerCircle {
  border-radius: 50%;
}

.spinnerCircle-medium {
  background: conic-gradient(
    rgba(228, 108, 57, 0) 5%,
    rgba(228, 108, 57, 0.5),
    rgba(186, 17, 117, 1)
  );
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.spinnerCenter {
  border-radius: 50%;
}
.spinnerCenter-medium {
  height: 14px;
  width: 14px;
  margin-left: 3px;
  margin-top: 3px;
  border-radius: 50%;
}

.spinnerDiv-xsmall {
  @extend .spinnerDiv-medium;
  margin: 0px;
}

.spinnerCircle-xsmall {
  @extend .spinnerCircle-medium;
  height: 12px;
  width: 12px;
}

.spinnerCenter-xsmall {
  @extend .spinnerCenter-medium;
  height: 6px;
  width: 6px;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fallback {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  color: var(--theme-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  margin: auto;
  animation-name: loading;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

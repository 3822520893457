/* Gradients */
$primary: linear-gradient(135deg, #de0788 0%, #a11c66 100%);
$secondary: linear-gradient(135deg, #d90b0b 0%, #a80867 100%);
$tertiary: linear-gradient(135deg, #e36933 0%, #a80867 100%);
$bar: linear-gradient(180deg, #feedfc 0%, #ffffff 100%);
/* Color Palette */
$violet: #ce0b80;
$pink_3_0: #DB2777;
$rouge: #8a1d5a;
$eggplant: #a80867;
$flame: #e36933;
$salmon: #ff8c69;
$aura-darkpink: #c33450;
$aura-violet: #B90E75;
$lightBlue: #cfe8fb;
/* UI Support Palette */
$danger: #d90b0b;
$warning: #ffd130;
$success: #20bf73;
/* UI Greyscale */
$gray-050: #fcfcfd;
$gray-100: #f7f8fb;
$gray-200: #e3ebf0;
$gray-300: #c2d1d9;
$gray-400: #7d8f9b;
$gray-500: #667c8a;
$gray-600: #5a6d79;
$gray-700: #495a63;
$gray-800: #3a474e;
$gray-900: #263238;
/* Basic */
$white: #ffffff;
$black: #000000;
/* Transparency (Black) */
$black-10: rgba(0, 0, 0, 0.1);
$black-20: rgba(0, 0, 0, 0.2);
$black-30: rgba(0, 0, 0, 0.3);
$black-40: rgba(0, 0, 0, 0.4);
$black-50: rgba(0, 0, 0, 0.5);
$black-60: rgba(0, 0, 0, 0.6);
$black-70: rgba(0, 0, 0, 0.7);
$black-80: rgba(0, 0, 0, 0.8);
$black-90: rgba(0, 0, 0, 0.9);
/* Transparency (White) */
$white-10: rgba(255, 255, 255, 0.1);
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$white-40: rgba(255, 255, 255, 0.4);
$white-50: rgba(255, 255, 255, 0.5);
$white-60: rgba(255, 255, 255, 0.6);
$white-70: rgba(255, 255, 255, 0.7);
$white-80: rgba(255, 255, 255, 0.8);
$white-90: rgba(255, 255, 255, 0.9);

$xs: 4px; /* baseline */
$s: calc($xs * 2); /* 8px */
$sm: calc($xs * 3); /* 12px */
$m: calc($xs * 4); /* 16px */
$ml: calc($xs * 6); /* 24px margins and gutters */
$l: calc($xs * 8); /* 32px */
$xl: calc($xs * 16); /* 64px */
$xxl: calc($xs * 32); /* 128px */
$xxxl: calc($xs * 64); /* 256px */

:root {
  --xs: 4px; /* baseline */
  --s: calc(var(--xs) * 2); /* 8px */
  --sm: calc(var(--xs) * 3); /* 12px */
  --m: calc(var(--xs) * 4); /* 16px */
  --ml: calc(var(--xs) * 6); /* 24px margins and gutters */
  --l: calc(var(--xs) * 8); /* 32px */
  --xl: calc(var(--xs) * 16); /* 64px */
  --xxl: calc(var(--xs) * 32); /* 128px */
  --xxxl: calc(var(--xs) * 64); /* 256px */
}

.container {
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 2rem;
}

.popOver {
  background-color: var(--gray-100);
}

.infoTitle {
  display: flex;
  justify-content: flex-start;
}

.infoContent {
  max-width: 280px;
}

.infoFooter {
  margin-top: 12px;
}

.infoSection {
  margin: 16px 0;

  .infoSubSection {
    margin-top: 8px;
  }
}

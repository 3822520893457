.sessionExpiredModalContainer {
  min-width: 450px !important;
  width: 20vw;
}

.sessionExpiredModalContent {
  display: flex;
  align-items: center;
  min-height: 20vh !important;
}

.sessionExpiredModal {
  padding: 0 50px;
  margin-bottom: -65px;
}

.sessionExpiredModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: -85px;
}

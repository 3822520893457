@import '../../../styles/typography.scss';
@import '../../../components/button/button.module.scss';

.jobsRunningModal {
  min-width: 520px;
  :global .ant-modal-content {
    border-radius: 16px;
  }
  :global .ant-modal-header {
    border-radius: 16px;
    border-bottom: none;
    padding: var(--l) var(--l) var(--m) var(--l);
  }
  :global .ant-modal-body {
    padding: var(--m);
    overflow-y: auto;
    max-height: 600px;
  }
  :global .ant-modal-close {
    outline: none;
    width: 24px;
    height: 24px;
    margin: 12px 32px 0 0;
    :global .ant-modal-close-x {
      width: 24px;
      height: 24px;
    }
  }
}

:global .ant-notification-notice {
  border-radius: 16px;
}

.modalTitleDiv {
  display: flex;
  .jobsRunningDiv {
    width: 24px;
    height: 24px;
    display: flex;
    background: #f7f8fb;
    border-radius: 34px;
    .jobsRunning {
      @extend .--Micro-Medium;
      color: #de0788;
      margin: auto;
    }
  }

  .modalTitle {
    @extend .--Body-Small-Medium;
    color: var(--gray-900);
    margin-left: var(--s);
  }
}

.description {
  @extend .--Body-Small-Regular;
  color: var(--gray-500);
  display: unset;
}

.contentClassName {
  min-height: fit-content;
  padding: 16px 32px;
  border-bottom: 1px solid #f0f0f0;
}

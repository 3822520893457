@import 'src/styles/variables.scss';

.incompleteDownloadsAlert {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.content {
  padding: 0 1.2rem;
  min-height: 8vh !important;

  p {
    font-size: 1rem;
    font-weight: 400;
    color: $gray-500;
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: center;
}

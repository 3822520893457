@import '../../styles/variables.scss';

.container {
  position: relative;
}

.button {
  &:hover {
    border: 1px solid $gray-300;
    cursor: pointer;
  }

  background-color: transparent;
  user-select: none;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-200;
}

.buttonVariableWidth {
  width: 100%;
}

.buttonText {
  margin-right: 6px;
}

.buttonNoOutline {
  &:hover {
    border: 1px solid transparent;
  }
  border: 1px solid transparent;
}

.compactButton {
  padding: 8px 12px;
}

.closedDropdownPanel {
  margin: 0px;
  width: 0px;
}

.dropdownPanel {
  max-height: 50vh;
  min-width: 150px;
  overflow-y: auto;
  background-color: $white;
  position: absolute;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 4px 10px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 9000;
  list-style-type: none;
}

.dropdownOptionUserIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  border-bottom: 1px solid #f7f8fb;
  line-height: 0px;
}

.dropdownOption {
  padding: 16px;
  border-radius: 8px;
  line-height: 0px;
  cursor: pointer;
}

.dropdownHeaderOption {
  &:nth-last-child(1) {
    margin-top: 10px;
    border-top: 1px solid #f7f8fb;
  }
}

.highlightedDropdownOption {
  background-color: $gray-100;
}

.selectedOption {
  color: $violet;
}

.emailLabel {
  color: $violet;
}

.subtitle {
  width: 100%;
  color: gray;
  margin-bottom: 0px;
}

.fixedPosition {
  position: fixed;
}

.absolutePosition {
  position: absolute;
}

.buttonDisabled {
  &:hover {
    border: 1px solid $gray-200;
    cursor: not-allowed;
  }
  border: 1px solid $gray-200;
  color: $gray-400;
  cursor: not-allowed;
}

@import '../../styles/variables';

.headerText {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 200;
  margin: 48px 0px;
  width: 100%;
  font-size: 56px;
  text-align: center;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  width: 750px;
  margin: auto;
}

.link {
  position: relative;
  height: 244px;
  background-color: $white;
  margin: 0px 8px;
  box-shadow: 0px 4px 10px rgba(125, 143, 155, 0.05),
    0px 0px 10px rgba(125, 143, 155, 0.05);
  border-radius: 8px;
  padding: 16px;

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1),
      0px 8px 24px -8px rgba(0, 0, 0, 0.15);
  }
}

.icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.infoIcon {
  display: flex;
  justify-content: flex-end;
}

.lockIcon {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.title {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 26px;
  color: $violet;
}

.subtitle {
  display: flex;
  justify-content: center;
  margin-top: 4px;
  color: $gray-700;
}

.caseCodeModalContent {
  min-height: 18vh;
  padding: 0 25px;
}

.errorToast {
  font-weight: 500;
  &:nth-child(1) > .ant-message-notice-content {
    border-radius: 8px;
    padding: 25px;
    &:nth-child(1) > .ant-message-custom-content {
      display: flex;
      align-items: center;
      font-size: 0.95rem;
    }
  }
}

@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.font {
  @extend .--Caption-Regular;
}

.projectsModal {
  min-width: 520px;
  max-height: 40vw;
}

.contentClassName {
  min-height: fit-content !important;
  max-height: 60vh !important;
  padding: 0px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.contentClassNameSmall {
  min-height: 6vh !important;
  max-height: 8vh !important;
  padding: 0px 32px;
  border-bottom: 1px solid #f0f0f0;
}

.fieldSet {
  margin: 8px 8px 16px;
}

.textLabel {
  justify-content: unset;
  color: #667c8a;
}

.inputField {
  margin: 8px 0 4px 0;
}

.textWordingTitle {
  justify-content: unset;
  color: #667c8a;
  margin: 15px 0;
  font-weight: bold;
}

.textInput {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  outline-color: transparent;
  border: none;
  background: #f7f8fb;
  border-radius: 8px;
}

.textInputError {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  outline-color: transparent;
  border: 1px solid $danger;
  background: #f7f8fb;
  border-radius: 8px;
}

.infoIconButton {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  &:hover {
    background-color: #7d8f9b;
    &:nth-child(1) path:nth-of-type(2) {
      fill: $white;
    }
  }
}

.infoIconButtonError {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  &:nth-child(1) path:nth-of-type(1) {
    fill: #cc0000;
  }
  &:nth-child(1) path:nth-of-type(2) {
    fill: #cc0000;
  }
}

.tooltipContainer {
  cursor: pointer;
  &:nth-child(1) .ant-tooltip-inner {
    border-radius: 16px;
  }
}

.tooltipContent {
  padding: 8px;
}

.tooltipText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $gray-900;
}

.tooltipTextEmail {
  color: var(--color-red-0);
}


.premiumLabel {
  width: 36px;
  height: 20px;
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: var(--white);
  background-color: var(--violet);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

@import '../../../components/button/button.module.scss';

.createProjectModal {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 0px 32px 0 32px;
}

.supportiveText {
  @extend .--Legend-Regular;
  color: var(--gray-500);
  margin: var(--s) 0 var(--m) 0;
}

.userList {
  display: flex;
  flex-direction: column;
}

.userTile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyLink {
  @extend .--Caption-Semibold;
  margin: var(--l) 0 0 0;
  background: var(--gray-100);
  border-radius: 8px;
  border: none;
  .copyLinkText {
    background: -webkit-linear-gradient(100deg, #de0788, #a11c66);
    background-color: #de0788;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.emailInvite {
  display: flex;
}

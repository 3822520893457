.leftAlign {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.centerAlign {
  display: flex;
  justify-content: center;
  text-align: center;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.H2Semibold {
  /* H2 / Semibold */
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 88px;
}

.H4Semibold {
  /* H4 / Semibold */
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}

.H5Semibold {
  /* H5 / Semibold */
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

.H5Regular {
  /* H5 / Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.SubheadingSemibold {
  /* Subheading / Semibold */
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.SubheadingMedium {
  /* Subheading / Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.SubheadingRegular {
  /* Subheading / Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
}

.BodySmallMedium {
  /* Body / Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.BodySmallRegular {
  /* Body /Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.CaptionSemibold {
  /* Caption / Semibold */
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.CaptionMedium {
  /* Caption / Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.CaptionRegular {
  /* Caption / Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.LegendMedium {
  /* Legend / Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.LegendRegular {
  /* Legend / Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.MicroMedium {
  /* Micro / Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.MicroRegular {
  /* Micro / Regular */
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
}

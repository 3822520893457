@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.textButton {
  @extend .--Caption-Medium;
  color: var(--gray-500);
  padding: 8px;
  border-radius: 8px;
  user-select: none;
  display: inline-block;
}

.textButton:hover {
  background-color: var(--gray-100);
}

.textButton:active {
  background-color: var(--gray-200);
}

.defaultButton {
  @extend .--Caption-Semibold;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin: auto 0;
  color: var(--white);
  border-radius: 8px;
}

.primaryButton {
  @extend .defaultButton;
  background: var(--primary);
  color: var(--white);
}

.primaryButton:hover {
  background: var(--violet);
  color: var(--white);
}

.primaryButton:focus {
  color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.primaryButton:active {
  background: var(--eggplant);
  color: var(--white);
}

.primaryButton:disabled,
.primaryButton[disabled] {
  background: var(--gray-100);
  color: var(--gray-300) !important;
}

.secondaryButton {
  @extend .defaultButton;
  background: var(--gray-100);
  color: var(--violet);
}

.secondaryButton:hover {
  background: var(--gray-050);
  color: var(--violet);
}

.secondaryButton:focus {
  background: var(--gray-100);
  color: var(--violet);
  border: 1px solid var(--violet);
}

.secondaryButton:active {
  background: var(--gray-200);
  color: var(--violet);
  border: none;
  box-sizing: border-box;
}

.secondaryButton:disabled,
.secondaryButton[disabled] {
  background: var(--gray-100);
  color: var(--gray-300);
}

.ghostButton {
  @extend .defaultButton;
  background: var(--white);
  color: var(--gray-900);
  border: 1px solid;
  border-color: var(--gray-200);
}

.ghostButton:hover {
  background: var(--gray-100);
  border: 1px solid transparent;
  box-sizing: border-box;
}

.ghostButton:focus {
  border: 1px solid #cfe8fb; // note: light-blue color not part of design system, check with designer if needs to be added to colors.scss
}

.ghostButton:active {
  background: var(--gray-200);
  border: none;
}

.ghostButton:disabled,
.ghostButton[disabled] {
  background: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  color: var(--gray-300);
}

.primaryIconButtonWithoutText {
  @extend .primaryButton;
  padding: 0px;
  height: 40px;
  width: 40px;
}

.secondaryIconButtonWithoutText {
  @extend .secondaryButton;
  padding: 0px;
  height: 40px;
  width: 40px;
}

.ghostIconButtonWithoutText {
  @extend .ghostButton;
  padding: 0px;
  height: 40px;
  width: 40px;
}

.iconOnlyButtonWithoutText {
  @extend .defaultButton;
  background-color: rgba(0, 0, 0, 0); //set transparent background
  height: 20px;
  width: 20px;
  padding: 0px;
}

.iconOnlyButtonWithText {
  @extend .iconOnlyButtonWithoutText;
}

.primaryIconButtonWithText {
  @extend .primaryButton;
}

.secondaryIconButtonWithText {
  @extend .secondaryButton;
}

.ghostIconButtonWithText {
  @extend .ghostButton;
}

.iconAtLeft {
  margin-left: 8px;
}

.iconAtRight {
  margin-right: 8px;
}

.font {
  @extend .--Caption-Semibold;
}

.default {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin: auto 4px;
  color: $white;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
}

.iconOnly {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
}

.iconOnlyCompact {
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  flex-grow: 0;
}

.hasTitle {
  padding: 12px 16px;
}

.hasTitleCompact {
  padding: 8px 12px;
}

.primary {
  background: $primary;
  color: $white;
  &:hover {
    background: $violet;
    color: $white;
    cursor: pointer;
  }
  &:focus {
    color: $white;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
  &:active {
    color: $white;
  }
  &:disabled {
    background: $gray-100;
    color: $gray-300;
    cursor: not-allowed;
  }
}

.secondary {
  background: $gray-100;
  color: $violet;
  &:hover {
    background: $gray-050;
    color: $violet;
    cursor: pointer;
  }
  &:focus {
    background: $gray-100;
    color: $violet;
    border: 1px solid $violet;
  }
  &:active {
    background: $gray-200;
    color: $violet;
    border: none;
    box-sizing: border-box;
  }
  &:disabled {
    background: $gray-050;
    color: $gray-300;
    cursor: not-allowed;
  }
}

.ghost {
  background: $white;
  color: $gray-900;
  border: 1px solid $gray-200;
  cursor: pointer;
  &:hover {
    background: $white;
    border-color: transparent;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 5px rgba(125, 143, 155, 0.05);
    cursor: pointer;
  }
  &:focus {
    border-color: $lightBlue;
  }
  &:active {
    background: $gray-200;
    border-color: transparent;
  }
  &:disabled {
    background: $white;
    border-color: $gray-200;
    color: $gray-300;
    cursor: not-allowed;
  }
}

.borderless {
  background: transparent;
  color: $gray-900;
  border: 1px solid transparent;
  &:hover {
    background: $gray-100;
    border: 1px solid transparent;
    box-sizing: border-box;
  }
  &:focus {
    background: transparent;
    color: $violet;
    border: 1px solid $lightBlue;
  }
  &:active {
    background: $gray-200;
    border: 1px solid transparent;
  }
  &:disabled {
    background: $white;
    border: 1px solid transparent;
    color: $gray-300;
    cursor: not-allowed;
  }
}

.icon {
  margin-right: 10px;
}

.loaderContainer {
  display: flex;
  width: 44px;
  border-radius: 8px;
}

.iconLoaderContainer {
  display: flex;
  border-radius: 8px;
}

.loaderFinished {
  margin: auto;
}

@import '../../../styles/typography.scss';
@import '../../../components/button/button.module.scss';

.jobTile {
  background: #ffffff;
  /* Greyscale / Grey 200 */

  border: 1px solid #e3ebf0;
  box-sizing: border-box;
  border-radius: var(--m);
  margin-bottom: var(--m);
  width: 100%;
  .tileTitle {
    @extend .--Body-Small-Medium;
    color: var(--gray-900);
    padding: var(--m) var(--m) 0 var(--m);
  }
  .tileDescription {
    font-family: Graphik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    color: #263238;
    color: var(--gray-500);
    padding: var(--s) var(--m) 0 var(--m);
  }
  .progressBar {
    padding: var(--sm) var(--m) var(--sm) var(--m);
  }
  .failure {
    .failureInfo {
      display: flex;
      padding: 0 var(--m) 0 var(--m);
      .warningIcon {
      }
      .failureMsg {
        @extend .--Body-Small-Medium;
        color: #cc0000;
      }
    }
    .failureActions {
      display: flex;
      justify-content: space-between;
      padding: var(--ml) var(--m) var(--m) var(--m);
      .info {
        @extend .--Legend-Medium;
        color: var(--gray-900);
      }
      .action {
        .retryButton {
          @extend .--Caption-Semibold;
          color: var(--gray-900);
          border: 1px solid #e3ebf0;
          box-sizing: border-box;
          border-radius: 8px;
        }
      }
    }
  }
  .success {
    .successInfo {
      display: flex;
      padding: 0 var(--m) 0 var(--m);
      .successIcon {
      }
      .successMsg {
        @extend .--Body-Small-Medium;
        color: #20bf73;
      }
    }
    .successActions {
      display: flex;
      justify-content: space-between;
      padding: var(--m) var(--m) var(--m) var(--m);
      .close {
        padding-right: 5px;
        width: 100%;
        .closeButton {
          @extend .--Caption-Semibold;
          color: var(--gray-900);
          width: 100%;
          border: 1px solid #e3ebf0;
          box-sizing: border-box;
          border-radius: 8px;
          height: 40px;
        }
      }
      .see {
        padding-left: 5px;
        width: 100%;
        .seeButton {
          @extend .primaryButton;
          width: 100%;
          color: var(--white);
        }
      }
    }
  }
}

.jobTile:last-child {
  margin-bottom: 0;
}

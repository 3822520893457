@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.font {
  @extend .--Body-Small-Medium;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background: $white;
  border-radius: 8px;
  min-width: 600px;
  max-width: 75vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  margin: 24px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentNotScrollable {
  min-height: 40vh;
  max-height: 70vh;
}

.contentScrollable {
  min-height: 40vh;
  max-height: 70vh;
  overflow-y: auto;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  float: right;
  padding: 16px;
  &:nth-child(1n) > button {
    margin-left: 24px;
  }
}

.closeButtonContainer {
  &.left {
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }
}
